<template>
  <v-card>
    <v-row>
      <v-col cols="9" class="py-0">
        <v-card-title class="text-h5" @click="CopyToBuffer(item.surname)">
          {{ fullNameShortened }}
        </v-card-title>
        <v-card-subtitle
            v-if="item.ticket !== null"
            class="text-h8"

        >
        </v-card-subtitle>
      </v-col>
      <v-col cols="3" align-self="center">
        <v-btn icon @click="show = !show">
          <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-card-actions>
      <v-spacer></v-spacer>
    </v-card-actions>

    <v-expand-transition>
      <v-card-text v-show="show" class="py-0 pb-1">
        <div
            @click="
            CopyToBuffer(`${item.surname} ${item.name} ${item.patronymic}`)
          "
        >
          ФИО: {{ fullName }}
        </div>
        <div @click="CopyToBuffer(item.birthday)">
          Дата рождения: {{ item.birthday }}
        </div>
        <v-divider></v-divider>
        <div @click="CopyToBuffer(item.document.code)">
          Тип документа:
          {{ this.documentTypes[item.document.code] }}
        </div>
        <div @click="CopyToBuffer(item.document.number)">
          Номер документа: {{ item.document.number }}
        </div>
        <div @click="CopyToBuffer(item.document.countryCode)">
          Старана документа: {{ item.document.countryCode }}
        </div>


        <v-divider></v-divider>

        <div @click="CopyToBuffer(item.email)">
          Эл. почта: {{ item.email }}
        </div>
        <div @click="CopyToBuffer(item.phone)">
          Телефон: {{ item.phone }}
        </div>
        <v-divider>Билеты</v-divider>

        <div v-for="(personalTicket, index) in ticket" @click="CopyToBuffer(personalTicket.ticketNumber)">
          Билет {{ index % 2 === 0 ? 'туда' : 'обратно' }}: {{ personalTicket.ticketNumber }}
        </div>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
import {format} from "date-fns";
import DateFormat from "@/mixins/DateFormat";
import ClipboardData from "@/mixins/ClipboardData";

export default {
  name: "TrainTicketPassengerInfo",
  mixins: [DateFormat, ClipboardData],
  props: {
    ticket: {
      type: Array,
      required: true,
      default: () => [],
    },
    contactInfo: {
      type: Object,
    },
    item: {
      type: Object,
      require: true,
    },
    colorIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      show: false,
      documentTypes: {
        'C': 'Российский паспорт',
        'P': 'Российский загранпаспорт',
        'A': 'Национальный паспорт',
        'B': 'Свидетельство о рождении',
        'M': 'Воинское удостоверение',
        'S': 'Паспорт моряка',
        'СУ': 'Временное удостоверение личности',
        'СВ': 'Свидетельство на возвращение',
        'ВЖ': 'Вид на жительство',
        'БГ': 'Удостоверение личности без гражданства',
        'DP': 'Дипломатический паспорт',
        'SP': 'Служебный паспорт',
        'PA': 'Какой-либо паспорт',
        'F': 'Свидетельство о рождении или воинское удостоверение',
        'O': 'Удостоверение личности офицера',
        'L': 'Справка об утере паспорта',
        'R': 'Свидетельство на возвращение в РФ'
      },
    };
  },
  methods: {
    formatedDate(date) {
      return format(new Date(date), "dd.MM.yyyy");
    },
    formatedDateHM(date) {
      return date != "" ? format(new Date(date), "HH.mm dd.MM.yyyy") : "";
    },
  },
  computed: {
    /*    ticketAfterCheck() {
          return this.ticket.length > 0
              ? this.ticket.find(e => e.passengerId == this.item.id)
              : { number: "", date: "", price: "" };
        },
        passTickets() {
          return this.ticket.filter(e => e.passengerId == this.item.id)
        },*/
    document(documentType) {
      return this.documentTypes[documentType] || 'Неизвестный тип документа';

    },
    fullName() {
      return `${this.item.surname} ${this.item.name} ${this.item.patronymic ? this.item.patronymic : ""}`;
    },
    fullNameShortened() {
      return `${this.item.surname} ${this.item.name.substr(
          0,
          1
      )}.${this.item.patronymic ? this.item.patronymic.substr(0, 1) : ""}.`;
    },
  },
};
</script>
