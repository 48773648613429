<template>
  <v-row align="center">
    <v-col cols="12" md="5" align-self="start" class="text-center text-h5">
      <segment-station-info
          :name="trainTicketSegment.departureStation"
          :date="convertToDate(trainTicketSegment.departureDateTime)"
      ></segment-station-info>
    </v-col>
    <v-col cols="12" md="2" class="text-center text-h5">
      <div>
        Поезд: {{ trainTicketSegment.trainNumber }}
      </div>
      <span id="sity-arrow" class="text-h2 green--text darken-4--text">
        {{ $vuetify.breakpoint.xsOnly ? "☟" : "➫" }}
      </span>
    </v-col>
    <v-col cols="12" md="5" align-self="start" class="text-center text-h5">
      <segment-station-info
          :name="trainTicketSegment.arrivalStation"
          :date="convertToDate(trainTicketSegment.arrivalDateTime)"
      ></segment-station-info>
    </v-col>
    <!--      <v-row no-gutters align="center" class="mx-2">
            <v-col cols="12" md="6" class="d-flex align-start flex-column py-2">
              <div class="text-caption white&#45;&#45;text">Отправление</div>
              <div class="text-h6 font-weight-bold yellow&#45;&#45;text">{{ trainTicketSegment.departureStation }}</div>
              <div class="text-caption white&#45;&#45;text">{{ formatDate(trainTicketSegment.departureDateTime) }}</div>
            </v-col>

            <v-col cols="12" md="6" class="d-flex align-end flex-column text-right py-2">
              <div class="text-caption white&#45;&#45;text">Прибытие</div>
              <div class="text-h6 font-weight-bold yellow&#45;&#45;text">{{ trainTicketSegment.arrivalStation }}</div>
              <div class="text-caption white&#45;&#45;text">{{ formatDate(trainTicketSegment.arrivalDateTime) }}</div>
            </v-col>

            <v-col cols="12" class="d-flex justify-center">
              <v-btn color="orange" class="mt-4" text>{{ trainTicketSegment.actionText }}</v-btn>
            </v-col>
          </v-row>-->
  </v-row>
</template>

<script>
import SegmentStationInfo from "@/components/SoldedTicketsPage/SegmentStationInfo.vue";

export default {
  components: {SegmentStationInfo},
  props: {
    trainTicketSegment: {
      type: Object,
      required: true,
      default: () => [],
    },
  },
  mounted() {
  },
  methods: {
    convertToDate(dateString) {
      return new Date(dateString);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${day}.${month}.${year} ${hours}:${minutes}`;
    }
  },
};
</script>


<style scoped>
.train-segment {
  background-color: #333; /* Темный фон */
  color: #fff; /* Белый текст */
}

.train-segment .v-card-text {
  border-top: 4px solid #444; /* Полоса вверху */
  border-bottom: 4px solid #444; /* Полоса внизу */
}

.yellow--text {
  color: #FFD600; /* Желтый цвет для выделенного текста */
}
</style>
