<template>
  <v-card class="pa-3 accent" outlined>
    <v-row>
      <v-col class="text-center text-h6">
        {{ svc.Name }}
      </v-col>
    </v-row>
    <v-row v-if="svc.Emd" justify="center" class="text-center">
      <v-col cols="7">
        <v-chip color="orange">{{ svc.Emd }}</v-chip>
      </v-col>
      <v-col cols="5">
        <v-chip color="orange">{{ svc.Emdt }}</v-chip>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        Статус: <span :class="statusColor">{{ SvcStatus }}</span>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      statusColor: null,
    };
  },
  props: {
    svc: {
      type: Object,
      require: true,
    },
    colorIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    SvcStatus() {
      switch (this.svc.Status) {
        case "HN":
          this.statusColor = "warning--text";
          return `Услуга не подтверждена`;
        case "HD":
          this.statusColor = "warning--text";
          return `Услуга подтверждена, требуется оформление EMD`;
        case "HK":
          this.statusColor = "success--text";
          return `Услуга подтверждена, оформление EMD не требуется`;
        case "HI":
          this.statusColor = "success--text";
          return `Услуга оформлена`;
        case "UN":
          this.statusColor = "error--text";
          return `В услуге отказано`;
        default:
          this.statusColor = "error--text";
          return "";
      }
    },
  },
};
</script>

<style scoped></style>
