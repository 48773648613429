<template>
  <div>
    <v-card
      tile
      outlined
      class="px-5 py-2"
      :class="colorIndex % 2 === 0 ? 'item1' : 'item2'"
    >
      <v-chip class="on-border pr-0" color="orange">
        <span>Дата оформления </span>
        <v-chip color="green" class="ml-2">
          {{ createdMoment }}
        </v-chip>
      </v-chip>
      <v-row class="mt-2">
        <v-col md="10" cols="12">
          <!-- Данные рейса -->
          <v-row align="center">
            <v-col md="auto" cols="12">
              <v-col cols="12" class="text-center pa-0">
                <span class="text-h5 indigo--text darken-1--text ml-5">
                  {{ "ЖД" }}
                </span>
              </v-col>
              <v-col cols="12" class="text-center pa-0">
                <span
                  class="text-h6 green--text font-italic ml-5"
                  @click="CopyToBuffer(pass.orderId)"
                >
                  {{ pass.orderId }}
                </span>
              </v-col>
              <v-col cols="12" class="text-center pa-0">
                <span
                  class="text-h6 green--text font-italic ml-5"
                  v-if="pass.isRefundable"
                >
                  Возвратный
                </span>
                <span class="text-h6 red--text font-italic ml-5" v-else>
                  Не возвратный
                </span>
              </v-col>
            </v-col>
            <v-col md="auto" cols="12" class="trip-info" offset-md="1">
              <v-row dense justify="center">
                <v-col sm="auto" cols="12">
                  <v-col cols="12" class="text-center pa-0">
                    <span class=" darken-4--text">
                      {{ pass.fromCity }}
                    </span>
                  </v-col>
                  <v-col cols="12" class="text-center pa-0">
                    <span class="deep-orange--text darken-4--text">
                      {{ dateFromStringToString(pass.depDate) }}
                    </span>
                  </v-col>
                </v-col>
                <v-col sm="auto" cols="12" class="text-center">
                  <span
                    id="sity-arrow"
                    class="text-h2 green--text darken-4--text"
                  >
                    {{ $vuetify.breakpoint.xsOnly ? "☟" : "➫" }}
                  </span>
                </v-col>
                <v-col sm="auto" cols="12">
                  <v-col cols="12" class="text-center pa-0">
                    <span class=" darken-4--text">
                      {{ pass.toCity }}
                    </span>
                  </v-col>
                  <v-col cols="12" class="text-center pa-0">
                    <span class="deep-orange--text darken-4--text">
                      {{ dateFromStringToString(pass.arrDate) }}
                    </span>
                  </v-col>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- Пассажир -->
          <v-row justify="center" class="divider mt-5">
            <v-divider></v-divider>
            <span>Пассажир</span>
            <v-divider></v-divider>
          </v-row>
          <v-row
            align="center"
            no-gutters
            justify="center"
            justify-md="start"
            class="mt-5"
          >
            <v-col cols="2" sm="2" md="1">
              <v-img
                :src="require('@/assets/img/avatar.png')"
                :max-height="50"
                contain
              ></v-img>
            </v-col>
            <v-col cols="auto" class="text-center">
              <span
                class="text-h5 font-weight-bold"
                @click="CopyToBuffer(pass.fullName)"
              >
                {{ pass.fullName }}
              </span>
            </v-col>
            <v-col cols="12" sm="auto" class="text-center">
              <span
                class="text-h6 orange--text darken-4--text ml-5"
                @click="CopyToBuffer(pass.email)"
              >
                {{ pass.email }}
              </span>
            </v-col>
            <v-col cols="12" sm="auto" class="text-center">
              <span
                class="text-h6 deep-orange--text darken-1--text ml-5"
                @click="CopyToBuffer(pass.phone)"
              >
                {{ pass.phone }}
              </span>
            </v-col>
          </v-row>
          <!-- Служебная иформация -->
          <v-row justify="center" class="divider mt-5">
            <v-divider></v-divider>
            Служебная информация
            <v-divider></v-divider>
          </v-row>
          <v-row justify="center" justify-md="start" align-content="center">
            <v-col sm="auto" cols="12" class="text-center service-info">
              <v-chip
                class="pr-0"
                color="orange"
                @click="CopyToBuffer(pass.orderId)"
              >
                <span>Идентификатор заказа </span>
                <v-chip color="green" class="ml-2 chip-value">
                  {{ pass.orderId }}
                </v-chip>
              </v-chip>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="8" sm="auto" class="text-center">
              <v-tooltip left :color="pass.IsSold ? 'green' : 'red darken-1'">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    Cтатус билета:
                    <v-icon
                      large
                      :color="
                        pass.isSold
                          ? 'green'
                          : pass.isBook
                          ? 'orange'
                          : 'red darken-1'
                      "
                    >
                      mdi-credit-card-outline
                    </v-icon>
                  </span>
                </template>
                <span>{{ pass.status }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <!-- Кассир -->
          <v-row
            v-if="$store.getters.user.role == $enums.Roles.Admin"
            justify="center"
            class="divider mt-5"
          >
            <v-divider></v-divider>
            Кассир
            <v-divider></v-divider>
          </v-row>
          <v-row
            align="center"
            no-gutters
            justify="center"
            justify-md="start"
            class="mt-5"
            v-if="$store.getters.user.role == $enums.Roles.Admin"
          >
            <v-col cols="2" sm="2" md="1">
              <v-img
                :src="
                  require(pass.cashierRole == $enums.Roles.User
                    ? '@/assets/img/b2c-ico.png'
                    : pass.cashierRole == $enums.Roles.Admin
                    ? '@/assets/img/admin-ico.png'
                    : '@/assets/img/cashier-ico.png')
                "
                :max-height="50"
                contain
              ></v-img>
            </v-col>
            <v-col cols="auto" sm="auto" class="text-center">
              <span
                class="text-h5 font-weight-bold"
                @click="CopyToBuffer(pass.cashierName)"
              >
                {{ pass.cashierName }}
              </span>
            </v-col>
            <v-col cols="12" sm="auto" class="text-center">
              <span
                class="text-h6 orange--text darken-4--text ml-5"
                @click="CopyToBuffer(pass.cashierEmail)"
              >
                {{ pass.cashierEmail }}
              </span>
            </v-col>
          </v-row>
        </v-col>
        <!-- Кнопки -->
        <v-col md="2" v-if="pass.isSold || pass.isBook" cols="12">
          <v-row
            v-if="pass.isSold || pass.isBook"
            align-content="start"
            class="btn-group"
            justify="center"
          >
            <v-btn
              v-if="pass.isSold"
              width="90%"
              class="mt-2 move"
              color="green"
              elevation="0"
              @click="getPdf"
              :loading="isGettingPdf"
            >
              Получение pdf
            </v-btn>

            <v-btn
              v-if="pass.isSold && pass.isRefundable"
              width="90%"
              class="mt-7 move"
              color="#b20000"
              elevation="0"
              @click="onReturnTicket"
            >
              Возврат
            </v-btn>
            <v-btn
              v-if="pass.isSold"
              width="90%"
              class="mt-2 move"
              color="#764E15"
              elevation="0"
              @click="onRefund"
              :disabled="true"
              :loading="isRefundingTicket"
            >
              Аннул-е пока недоступно
            </v-btn>
            <v-btn
              v-if="pass.isBook && !pass.isSold"
              width="90%"
              class="mt-2 move"
              color="orange"
              elevation="0"
              @click="onCancelBook"
              :loading="cancelBookLoading"
            >
              Отмена брони
            </v-btn>
            <v-btn
              v-if="pass.isSold && false"
              width="90%"
              class="mt-2 move"
              color="orange"
              elevation="0"
              @click="onGetReceipt"
              :loading="isGettingReceipt"
            >
              Все данные
            </v-btn>
          </v-row>
        </v-col>

        <v-col
          md="2"
          v-else-if="$vuetify.breakpoint.mdAndUp"
          align-self="center"
          cols="12"
        >
          <v-row class="not-ticket mb-5">
            <v-col class="text-center">
              <span class="orange--text">NOT</span>
              <v-img
                :src="
                  require($vuetify.theme.dark
                    ? '@/assets/img/ticket-dark.png'
                    : '@/assets/img/ticket-light.png')
                "
                :max-height="150"
                contain
                class="mt-2"
              ></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <error-modal v-model="hasErrors" :error="error"></error-modal>

    <v-snackbar
      v-model="isCopiedToBuffer"
      color="warning"
      :timeout="1000"
      content-class="text-center"
      text
    >
      {{ "Скопировано - " + bufferText }}
    </v-snackbar>
  </div>
</template>

<script>
import ApiCalls from "@/mixins/booking/ApiCalls";
import OrderInfo from "@/components/SupportPage/Order/OrderInfo.vue";
import DateFormat from "@/mixins/DateFormat";
import ErrorModal from "@/components/UI/ErrorModal";

export default {
  name: "RailwaySoldedTicket",
  components: { ErrorModal, OrderInfo },
  mixins: [DateFormat, ApiCalls],
  props: {
    pass: {
      type: Object,
      require: true,
    },
    colorIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentTime: new Date(),
      isGettingPdf: false,
      isRefundingTicket: false,
      cancelBookLoading: false,
      isGettingReceipt: false,
      error: { Code: "", Message: "" },
      hasErrors: false,
      pdfByBase64: "",
      isCopiedToBuffer: false,
      bufferText: "",
      order: null,
      isGettingInfo: false,
      isShowInfo: false,
      payDialog: false,
      orderId: null,
    };
  },
  mounted: function() {
    var _this = this;
    this.orderId = this.pass.orderId;
    this.timer = setInterval(function() {
      _this.currentTime = new Date();
    }, 1000);
  },
  computed: {
    allowedToRefundMs() {
      return (
        780000 +
        (Date.parse(this.pass.CreatedAt) - Date.parse(this.currentTime))
      );
    },
    allowedToRefundTime() {
      return this.fmtMSS(this.allowedToRefundMs);
    },
    createdMoment() {
      let dateTime = this.pass.createdAt.split("T");
      let date = dateTime[0].split("-");
      let time = dateTime[1].split(":");
      return `${date[2]}.${date[1]}.${date[0]} ${time[0]}:${time[1]}`;
    },
  },
  methods: {
    async showAll(e) {
      if (!this.order) {
        await this.getInfo();
      }
      this.isShowInfo = !this.isShowInfo;
    },
    async getInfo() {
      this.isGettingInfo = true;
      try {
        let response = await this.$axios.get(
          `/Order?regnum=${this.pass.RegNum}&surname=${this.pass.Lastname}&pult=${this.pass.Pult}`
        );
        this.order = response.data.Answer.Order;
      } catch (e) {
        this.hasErrors = true;
        this.error = this.error = {
          Code: e.response.status,
          Message: e.response.data.Message,
        };
        return;
      } finally {
        this.isGettingInfo = false;
      }
    },
    async onGetReceipt() {
      this.isGettingReceipt = true;
      try {
        let resp = await this.getRecieptInfo(
          this.pass.RegNum,
          this.pass.Lastname,
          this.pass.Pult
        );
      } catch (e) {
        this.hasErrors = true;
        this.error = {
          Code: e.response.status,
          Message: e.response.data.Message,
        };
      } finally {
        this.isGettingReceipt = false;
      }
    },
    async onRefund() {
      this.$store.dispatch({
        type: "setActions",
        accept: async () => {
          try {
            await this.refundTicket(this.pass);
          } catch (e) {
            this.hasErrors = true;
            this.error = {
              Code: e.response.status,
              Message: e.response.data.Message,
            };
          }
        },
      });
      this.$store.commit("setMessage", "что хотите аннулировать билет");
      this.$store.commit("openDialog", true);
    },

    async onCancelBook() {
      this.$store.dispatch({
        type: "setActions",
        accept: async () => {
          try {
            await this.railwayCancelBooking();
            this.pass.isBook = false;
          } catch (e) {
            this.hasErrors = true;
            this.error = {
              Code: e.response.status,
              Message: e.response.data.Message,
            };
          }
        },
      });
      this.$store.commit("setMessage", "что хотите отменить бронь");
      this.$store.commit("openDialog", true);
    },

    onReturnTicket() {
      this.$router.push(
        "/show-receipt" +
          "?regnum=" +
          this.pass.RegNum +
          "&surname=" +
          this.pass.Lastname +
          "&pult=" +
          this.pass.Pult +
          "&isRefundable=" +
          this.pass.IsRefundable +
          "&byBuy=false"
      );
    },

    async getPdf() {
      this.isGettingPdf = true;
      console.log(this.pass);
      try {
        this.pdfByBase64 = await this.railwayGetPDF();
        this.downloadPdf(this.pdfByBase64);
      } catch (e) {
        console.log(e);
      } finally {
        this.isGettingPdf = false;
      }
    },

    downloadPdf(str) {
      try {
        const linkSource = `data:application/pdf;base64,${str}`;
        const downloadLink = document.createElement("a");
        const fileName = `${this.pass.fullName}_${this.pass.fromCity}_${this.pass.toCity}.pdf`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        console.log("download complete");
      } catch (e) {
        console.log("download Error");
        console.log(e);
        throw e;
      }
    },
    CopyToBuffer(str) {
      const el = document.createElement("textarea");
      el.value = str;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.bufferText = str;
      this.isCopiedToBuffer = true;
    },
  },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .on-border {
    transform: translateX(50%);
    right: 50%;
    left: inherit !important;
  }
}
.on-border {
  position: absolute;
  top: -15px;
  left: 20px;
}

@media (min-width: 600px) {
  .trip-info span {
    font-size: 15px !important;
  }
  #sity-arrow {
    font-size: 30px !important;
  }
}

@media (min-width: 1200px) {
  .trip-info span {
    font-size: 25px !important;
  }
  #sity-arrow {
    font-size: 50px !important;
  }
}
.chip-value {
  margin-right: 0;
}

.v-chip-group--column .v-slide-group__content {
  justify-content: center;
}

.divider {
  font-size: 20px;
}

.v-divider {
  margin: auto 10px;
  border-top: 2px dashed rgb(114, 114, 114);
}

.btn-group,
.not-ticket {
  padding: 10px 0;
  color: #12121244;
}

.not-ticket span {
  font-size: 50px;
  color: orange;
  min-height: 100%;
}

.move:hover {
  transform: scale(1.05) translateX(-1em);
}

.service-info > .v-chip {
  margin: 5px 5px;
}
</style>
