<template>
  <div>
    <v-card tile outlined class="titleCard px-5 py-2 mt-6" :class="colorIndex % 2 === 0 ? 'item1' : 'item2'">

      <!-- Колонка для даты и статуса -->
      <info-chip class="on-border-right pr-0" title="Дата оформления" :value="formatDate(ticketitem.createAt)"/>
      <v-chip :color="getStatusColor(ticketitem.status)" class="on-border-left text-h5 px-5 py-5">
        {{ getStatusText(ticketitem.status) }}
      </v-chip>

      <!-- #endregion -->
      <!-- #region Инфа о полете -->
      <v-row class="pa-2 mt-1">
        <v-col md="10" cols="12">
          <v-row align="center" justify="space-between" class="px-3">
            <v-col md="10" cols="12">
              <v-col v-for="train in ticketitem.trainTicketSegment" :key="train.carNumber">
                <TrainInfo v-if="train" :trainTicketSegment="train"></TrainInfo>
              </v-col>
            </v-col>
          </v-row>
          <v-row justify="center" class="divider mt-1">
            <v-divider class="dashed-divider"></v-divider>
            <span>Пассажиры</span>
            <v-divider class="dashed-divider"></v-divider>
          </v-row>

          <!-- Информация о пассажирах -->
          <v-row>
            <v-col md="4" cols="12" v-for="(pass, i) in ticketitem.trainPassengers" :key="i">
              <train-ticket-passenger-info :item="pass" :ticket="pass.trainTickets"
                                           :contactInfo="ticketitem"></train-ticket-passenger-info>
            </v-col>
          </v-row>
          <!-- #endregion -->
          <!-- #region Divider Служебная информация-->
          <v-row justify="center" class="divider mt-1">
            <v-divider class="dashed-divider"></v-divider>
            <span>Служебная информация</span>
            <v-divider class="dashed-divider"></v-divider>
          </v-row>
          <v-row justify="center" justify-md="start" align-content="center" class="mt-1">
            <v-col sm="auto" md="11" class="service-info">
              <info-chip title="Цена" :value="ticketitem.cost.toString()"></info-chip>
              <info-chip v-if="$store.getters.user.role === $enums.Roles.Admin" title="Базовая цена"
                         :value="ticketitem.baseCost.toString()" class="ml-2"></info-chip>
              <info-chip title="Поставщик" :value="ticketitem.provider" class="ml-2"></info-chip>
            </v-col>
          </v-row>
          <v-divider class="my-3"></v-divider>
          <v-row align="center" no-gutters justify="center" justify-md="start" class="mt-3"
                 v-if="$store.getters.user.role === $enums.Roles.Admin">
            <v-col cols="2" sm="2" md="1">
              <v-img :src="getImg" :max-height="50" contain></v-img>
            </v-col>
            <v-col cols="auto" sm="auto" class="text-center">
              <span class="text-h5 font-weight-bold">
                {{ ticketitem.account.name }}
              </span>
            </v-col>
            <v-col cols="12" sm="auto" class="text-center">
              <span class="text-h6 orange--text darken-4--text ml-5">
                {{ ticketitem.account.email }}
              </span>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="2" cols="12">
          <v-row align-content="start" class="btn-group" justify="center">
            <v-btn
                v-for="button in buttons"
                :key="button.text"
                :width="button.width"
                :class="button.class"
                :color="button.color"
                :elevation="button.elevation"
                :loading="button.loading"
                @click="button.onClick"
            >
              {{ button.text }}
              <v-progress-linear
                  v-if="button.loading"
                  :value="progress"
                  color="blue"
                  height="10"
              ></v-progress-linear>
            </v-btn>
          </v-row>
          <!--            <v-col v-if="ticketitem.status !== 'BOOKED'&&
                      ticketitem.status !== 'PAID' && ticketitem.status !== 'PARTIALLY_REFUNDED' || this.ticketitem.status !== 'TICKETED'
                      " class="not-ticket text-center" width="90%">
                        <span class="orange&#45;&#45;text text-h3">NOT</span>
                        <v-img :src="require($vuetify.theme.dark
                          ? '@/assets/img/ticket-dark.png'
                          : '@/assets/img/ticket-light.png')
                        " :max-height="150" :max-width="300" contain class="mt-2"></v-img>
                      </v-col>-->
        </v-col>
      </v-row>
    </v-card>
    <div>
      <TrainTicketsModal
          :trainTickets="ticketitem.trainPassengers.map(passenger => passenger.trainTickets)"
          :passengers="ticketitem.trainPassengers"
          :trainSegments="ticketitem.trainTicketSegment"
          :isVisible="dialogVisible"
          @update:isVisible="updateDialogVisibility"
      />
    </div>
  </div>
</template>


<script>
import InfoChip from "@/components/UI/InfoChip.vue";
import ErrorModal from "@/components/UI/ErrorModal.vue";
import TicketPassengersInfo from "@/components/SoldedTicketsPage/TicketPassengerInfo.vue";
import FlightInfo from "@/components/SoldedTicketsPage/FlightInfo.vue";
import RefundModal from "@/components/SoldedTicketsPage/RefundModal.vue";
import DateFormat from "@/mixins/DateFormat";
import ApiCalls from "@/mixins/booking/ApiCalls";
import ClipboardData from "@/mixins/ClipboardData";
import TrainSegment from "@/components/SoldedTicketsPage/TrainSegment.vue";
import TrainInfo from "@/components/SoldedTicketsPage/TrainInfo.vue";
import TrainTicketPassengerInfo from "@/components/EmployeePage/TrainTicketPssengerInfo.vue";
import TrainTicketsModal from "@/components/SoldedTicketsPage/TrainTicketsModal.vue";
import {docTypes as documentTypes, railwayOrderStatuses} from "@/services/constants";
import {parse} from "date-fns";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${day}.${month}.${year} ${hours}:${minutes}`;
};
export default {
  name: "SoldedTicketTrainItem",
  components: {
    TrainInfo,
    InfoChip,
    ErrorModal,
    TrainTicketsModal,
    TicketPassengersInfo,
    FlightInfo,
    RefundModal,
    TrainSegment,
    TrainTicketPassengerInfo
  },
  data() {
    return {
      startTime: null,
      downloadTime: 15000,
      trainTicketsData: [],
      dialogVisible: false,
      /*trainTickets: [],*/
      isExtendedPassengers: false,
      isDownload: false,
      canceling: false,
      isRailway: false,
      currentTime: new Date(),
      pdfUrl: "",
      voiding: false,
      refunding: false,
      buing: false,
      statusing: false,
      gettingStatus: false,
      refundDialog: false,
      refundData: null,
      orderStatus: "",
      documentTypes,
      railwayOrderStatuses,
    }
  },

mixins: [DateFormat, ApiCalls, ClipboardData],
  props: {
    ticketitem: {
      type: Object,
      require: true,
    },
    colorIndex: {
      type: Number,
      default: 0,
    },
    bookingData: {
      type: Array,
      default: () => [],
    },
  },
  methods: {

    updateDialogVisibility(visible) {
      this.dialogVisible = visible;
    },
    formatDate,
    toggleExtendedPassengers() {
      this.isExtendedPassengers = !this.isExtendedPassengers;
    },
    async onGetRefundData() {
      this.$store.dispatch({
        type: "setActions",
        accept: async () => {
          await this.getRefundData();
        },
      });
      const TicketData = [];
      for (const segment of this.ticketitem.trainPassengers) {
        console.log(segment)
        if (segment.trainTickets) {
          TicketData.push(segment.trainTickets);
          console.log("pushed")
        }
      }


      this.trainTicketsData = TicketData;

      this.dialogVisible = true;
    },

    getStatusText(statusCode) {
      switch (statusCode) {
        case 'BOOKED':
          return 'Забронирован';
        case 'CANCELLED':
          return 'Отменен';
        case 'BOOK_CANCELED':
          return 'Бронь отменена'
        case 'WAIT_AUTHORIZATION':
          return 'Ожидание авторизации';
        case 'REFUND_AUTHORIZED':
          return 'Возврат денег разрешен';
        case 'REFUNDED':
          return 'Возвращено';
        case 'PARTIALLY_REFUNDED':
          return 'Частично возвращено';
        case 'PAID':
          return 'Оплачено';
        case 'TICKETED':
          return 'Выписано';
        case 'AWAIT_PAYMENT':
          return 'Выписка и оплата возможны через саппорт';
        case 'ANNULMENT':
          return 'Аннулировано';
        case 'TICKETED_IN_PROVIDER':
          return 'Выписан у поставщика, но не оплачен у нас';
        case 'PARTIALLY_ANNULMENT':
          return 'Частично аннулировано';
        case 'PARTIALLY_TICKETED':
          return 'Частично выписано';
        case 'EXCHANGED':
          return 'Произведен обмен';
        case 'PARTIALLY_EXCHANGED':
          return 'Частичный обмен';
        default:
          return 'Статус неизвестен';
      }
    },


    getStatusColor(statusCode) {
      switch (statusCode) {
        case 'BOOKED':
          return 'blue';
        case 'CANCELLED':
        case 'ANNULMENT':
        case 'BOOK_CANCELED' :
        case 'PARTIALLY_ANNULMENT':
          return 'red';
        case 'REFUNDED':
        case 'PARTIALLY_REFUNDED':
          return 'orange';
        case 'PAID':
        case 'TICKETED':
        case 'TICKETED_IN_PROVIDER':
        case 'PARTIALLY_TICKETED':
          return 'green';
        case 'AWAIT_PAYMENT':
        case 'WAIT_AUTHORIZATION':
        case 'REFUND_AUTHORIZED':
          return 'yellow';
        case 'EXCHANGED':
        case 'PARTIALLY_EXCHANGED':
          return 'purple';
        default:
          return 'grey';
      }
    },
    getDocumentName(code) {
      return this.documentTypes[code] || 'Неизвестный тип документа';
    },
    async loadRailwayData(text, priceRange, skip, take) {
      let resp = await this.$axios.get(this.$enums.Endpoints.RailwayOrder.GetAllRailwayOrders + `?accountId=${this.$store.state.user.id}` +
          `&text=${text}` +
          `&minPrice=${priceRange[0]}` +
          `&maxPrice=${priceRange[1]}` +
          `&page=${skip}` +
          `&take=${take}`);
      console.log(resp, "ae");
      this.bookingData = resp.data.item1;
      console.log(this.bookingData, "book");
      this.pageCount = Math.ceil(resp.data.item2 / take);
      this.loading = false;
    },
    async downloadReceipt2() {
      this.isDownload = true
      try {
        let resp = await this.$axios.get(
            this.$enums.Endpoints.RailWay.DownloadPdf + `?billingNumber=${parseInt(this.ticketitem.billingNumber)}`
        );
        let blob = new Blob([resp.data], {type: "application/pdf"}),
            url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `Receipt.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
        //window.location.assign(url);
      } catch (e) {
        throw e;
      } finally {
        this.isDownload = false;

      }
    },
    async downloadReceipt() {
      this.isDownload = true;
      this.startTime = Date.now();
      try {
        let resp = await this.$axios.get(
            this.$enums.Endpoints.RailwayData.GetRailwayReceiptPDF + `?billingNumber=${parseInt(this.ticketitem.billingNumber)}`, {responseType: 'blob'}
        );
        console.log(resp.data)
        let blob = new Blob([resp.data], {type: "application/pdf"});
        let url = window.URL.createObjectURL(blob);

        let randomFourDigits = Math.floor(1000 + Math.random() * 9000);

        var a = document.createElement("a");
        a.href = url;
        a.download = `МаршрутКвитанция_${randomFourDigits}.pdf`;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        this.isDownload = false;
        this.startTime = null;
        await this.loadRailwayData();
      }
    },


    async onBuy() {
      this.$store.dispatch({
        type: "setActions",
        accept: () => {
          this.buyTicket();
        },
      });

      this.$store.commit("setMessage", "что хотите оплатить билет " + this.ticketitem.baseCost + " руб");
      this.$store.commit("openDialog", true);
    },
    async buyTicket() {
      this.buing = true;
      try {
        console.warn("Оформление билета");
        let body = {
          billingNumber: this.ticketitem.billingNumber
        };
        let resp1 = await this.$axios.post(this.$enums.Endpoints.RailwayOrder.Pay, body);
        console.log(resp1)
        console.warn("Билет оформлен");
        this.ticketitem.status = 'PAID';
        window.location.reload()
      } finally {
        console.warn("Билет не оформлен");
        this.buing = false;
      }
    },

    async getStatus() {
      try {
        this.gettingStatus = true;
        let body = {
          billingNumber: this.ticketitem.billingNumber,
          signature: this.ticketitem.signature
        };

        let resp = await this.$axios.post(
            this.$enums.Endpoints.RailwayOrder.GetStatus, body);           
        let status = this.railwayOrderStatuses[resp.data];

        this.$store.state.snackbar = {
          show: true,
          message: `Текущий статус заказа - ${status !== undefined ? status : "Статус неопределён"}`,
          color: resp.data == "PAID" ? "green" : "orange",
          timeout: 3000,
          contentClass: 'text-center text-h5',
        };
        if(this.ticketitem.status !== status){
           await this.$axios.get(
            this.$enums.Endpoints.RailwayOrder.UpdateStatus + `?billingNumber=${parseInt(this.ticketitem.billingNumber)}&status=${resp.data}`
        );
        }

      } catch (e) {
        throw e;
      }finally {
        this.gettingStatus = false;
      }
    },

    async onCancelBook() {
      this.$store.dispatch({
        type: "setActions",
        accept: async () => {
          await this.cancelBook();
        },
      });
      this.$store.commit("setMessage", "что хотите отменить бронь");
      this.$store.commit("openDialog", true);
    },
    openPdf() {
      this.pdfUrl = this.ticketitem.tickets[0]?.receiptUrl;
      let downloadLink = this.pdfUrl;
      return window.open(downloadLink);
    },
    async onVoid() {
      this.$store.dispatch({
        type: "setActions",
        accept: async () => {
          await this.void();
        },
      });
      this.$store.commit("setMessage", "что хотите аннулировать билет");
      this.$store.commit("openDialog", true);

    },
    async void() {
      this.voiding = true;
      try {
        let resp = await this.$axios.post(
            this.$enums.Endpoints.Order.Void + `?data=${this.ticketitem.workId}`
        );
        this.ticketitem.status = 5;
      } catch (e) {
        throw e;
      } finally {
        this.voiding = false;
        window.location.reload()
      }
    },
    async cancelBook() {
      this.canceling = true;
      let billingNumber = parseInt(this.ticketitem.billingNumber, 10)
      console.log(billingNumber)
      try {
        let body = {
          billingNumber: billingNumber,
          signature: this.ticketitem.signature,
        }
        let resp = await this.$axios.post(
            this.$enums.Endpoints.RailwayOrder.Cancel, body
        );
        this.ticketitem.status = 4;
      } catch (e) {
        throw e;
      } finally {
        this.canceling = false;
        window.location.reload()

      }
    },
    refund() {
      this.refundDialog = false;
      this.ticketitem.status = 6;
    },
    async getRefundData() {
      this.refunding = true;
      try {
        let body = {
          locator: this.ticketitem.locator,
          blanksId: this.ticketitem.blanksId
        }
        console.log(body)
        let resp = await this.$axios.get(
            this.$enums.Endpoints.RailwayOrder.Refund + `?id=${this.ticketitem.workId}`
        );
        this.refundData = resp.data;

        this.refundDialog = true;
      } catch (e) {
        throw e;
      } finally {
        this.refunding = false;
      }
    },
  },
  computed: {
    trainTickets() {
      return this.ticketitem.trainPassengers.map(passenger => passenger.trainTickets)
    },
    progress() {
      if (!this.startTime) {
        return 0;
      }
      const elapsed = Date.now() - this.startTime;
      return Math.min((elapsed / this.downloadTime) * 100, 100);
    },
    buttons() {
      return [
        {
          text: 'Получение pdf',
          condition: this.ticketitem.status === 'PAID' || this.ticketitem.status === 'TICKETED' || this.ticketitem.status === 'PARTIALLY_REFUNDED',
          width: "90%",
          class: "mt-2 move",
          color: "green",
          elevation: "0",
          loading: this.isDownload,
          onClick: this.downloadReceipt
        },
        {
          text: 'Оформить',
          condition: this.ticketitem.status === 'BOOKED',
          width: "90%",
          class: "mt-2 move",
          color: "orange",
          elevation: "0",
          loading: this.buing,
          onClick: this.onBuy
        },
        {
          text: 'Отмена брони',
          condition: this.ticketitem.status === 'BOOKED',
          width: "90%",
          class: "mt-2 move",
          color: "red",
          elevation: "0",
          loading: this.canceling,
          onClick: this.onCancelBook
        },
        {
          text: 'Возврат',
          condition: this.ticketitem.status === 'PAID' || this.ticketitem.status === 'PARTIALLY_REFUNDED' || this.ticketitem.status === 'TICKETED',
          width: "90%",
          class: "mt-2 move",
          color: "red",
          elevation: "0",
          loading: this.refunding,
          onClick: this.onGetRefundData
        },
        {
          text: 'Получить статус',
          condition: true,
          width: '90%',
          class: 'mt-2 move',
          color: '#10ADAD',
          loading: this.gettingStatus,
          elevation: '0',
          onClick: this.getStatus
        }

      ].filter(button => button.condition);
    },
    getImg() {
      return require(this.ticketitem.account.role === this.$enums.Roles.User
          ? "@/assets/img/b2c-ico.png"
          : this.ticketitem.account.role === this.$enums.Roles.Admin
              ? "@/assets/img/admin-ico.png"
              : "@/assets/img/cashier-ico.png");
    },
    allowedToRefundMs() {
      return (
          780000 +
          (Date.parse(this.ticketitem.createdAt) - Date.parse(this.currentTime))
      );
    },
    allowedToRefundTime() {
      return this.fmtMSS(this.allowedToRefundMs);
    },
  },
  mounted() {
  }

};

</script>

<style scoped>
.rounded-card {
  border: solid 1px orange !important;
}

.titleCard {
  border: solid 2px orange !important;
}

.on-border-right {
  position: absolute;
  top: -15px;
  right: 20px;
}

.on-border-left {
  position: absolute;
  top: -20px;
  left: 20px;
}

.divider {
  font-size: 20px;
}

.dashed-divider {
  margin: auto 10px;
  border-top: 2px dashed rgb(114, 114, 114);
}

.btn-group,
.not-ticket {
  padding: 10px 0;
  color: #12121244;
}

.not-ticket span {
  font-size: 50px;
  color: orange;
  min-height: 100%;
}
</style>