<template>
  <div>
    <div class="darken-4--text">
      {{ name }}
    </div>
    <div class="deep-orange--text darken-4--text">
      {{ formatedDate }}
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";

export default {
  name: "SegmentStationInfo",
  props: {
    name: {
      type: String,
      require: true,
    },
    date: {
      type: Date,
      require: true
    },
  },
  computed: {
    formatedDate() {
      return format(this.date, "HH:mm dd.MM.yyyy");
    }
  },
};
</script>

<style>
</style>
