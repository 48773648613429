<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            <v-row>
                <v-col cols="12" class="py-1">
                    {{ value.id }}
                </v-col>
                <v-col cols="12" class="py-1">
                    Возврат: <span :class="isRefundable.color">{{ isRefundable.text }}</span>
                </v-col>
                <v-col cols="12" class="py-1">
                    Общая cумма: {{ value.total }}
                </v-col>
            </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-divider class="mb-5"></v-divider>
            <div v-if="value.taxes && value.taxes.length > 0">
                <v-row  v-for="(tax, i) in value.taxes" :key="i">
                    <v-col cols="5" class="text-end py-0">
                        Код: {{ tax.code }}
                    </v-col>
                    <v-col cols="7" class="text-start py-0">
                        Сумма: {{ tax.amount }}
                    </v-col>
                </v-row>
            </div>
            <div v-else class="text-center">
                    Нет данных
            </div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
export default {
    name: "RefundInfoItem",
    props: {
        value: {
            type: Object,
            require: true,
        }
    },
    computed: {
        isRefundable() {
            return {
                text: this.value.isRefundable ? 'Да' : 'Нет',
                color: this.value.isRefundable ? 'green--text' : 'red--text',
            }
        }
    }
};
</script>