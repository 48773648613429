<template>
  <v-dialog v-model="isVisible" persistent max-width="800px">
    <v-card>
      <v-toolbar color="orange" dark>
        <v-toolbar-title>Информация о билетах</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="table-container">
        <v-simple-table class="tickets-table">
          <template v-for="(segment, segmentIndex) in trainSegments">
            <thead>
            <tr>
              <th class="text-left">{{ segment.arrivalStation }} -> {{ segment.departureStation }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(ticket, ticketIndex) in segment.trainTickets" :key="ticketIndex">
              <td>
                <div class="ticket-info">
                  <div class="status">{{ ticket.status }}</div>
                  <div>Возврат: {{ ticket.isRefundAvailable ? 'Доступен' : 'не доступен' }}</div>
                  <div>Пассажир: <strong> {{ ticket.surname }} {{ ticket.name }} {{ ticket.patronymic }}</strong></div>
                </div>
                <v-checkbox color="orange" :label="`Выбрать Билет ${ticket.ticketNumber}`"
                            :disabled="ticket.status === 'Returned' || !ticket.isRefundAvailable"
                            @change="updateSelected(ticket, segment)"></v-checkbox>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>

      <div class="refund-section" v-if="refundAmount > 0">
        <v-row class="pa-3" align="center">
          <v-col cols="8" class="">
            <info-chip class="ma-2" title="Сбор MyAgent" :value="serviceSum"></info-chip>
            <info-chip class="ma-2" title="Комиссия на возврат" :value="refundComs"></info-chip>
            <info-chip class="ma-2" title="Штраф" :value="penalty"></info-chip>
          </v-col>
          <v-col cols="4" class="text-right">
          </v-col>
        </v-row>
      </div>

      <v-card-actions class="pa-6 justify-space-between">

        <v-btn color="green" @click="onComputeRefundSum" v-if="!refundAmount" :loading="loading"
               :disabled="!isAnyTicketSelected">
          Рассчитать сумму возврата
        </v-btn>
        <v-btn color="green" @click="onRefund" :loading="loading" v-if="refundAmount">
          Оформить возврат
        </v-btn>

        <div v-if="refundAmount" class="refund-amount-display">
          К возврату: <strong> {{ refundAmount }} ₽</strong>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InfoChip from "@/components/UI/InfoChip.vue";
import RefundSumPrice from "@/components/SoldedTicketsPage/RefundSumPrice.vue";
import Arrow from "@/components/UI/Arrow.vue";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

export default {
  components: {Arrow, RefundSumPrice, InfoChip},
  props: {
    trainTickets: {
      type: Array,
      required: true,
      default: () => []
    },
    isVisible: {
      type: Boolean,
      required: true
    },
    passengers: {
      type: Array,
      required: true
    },
    trainSegments: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      selected: [],
      loading: false,
      refunded: true,
      refundAmount: 0,
      maFee: 0,
      serviceSum: 0,
      penalty: 0,
      refundComs: 0,
    }
  },
  computed: {
    isAnyTicketSelected() {
      // Checks if any ticket in the array is selected
      return this.selected.length > 0;
    },

    computedCols(){
      return this.trainSegments.length > 1 ? 12 : 12 / this.trainSegments.length
    },
    computedMd() {
      return this.trainSegments.length > 1 ? 6 : 12;
    }
  },
  methods: {
    async onRefund() {
      this.$store.dispatch({
        type: "setActions",
        accept: async () => {
          await this.GoToRefund();
        },
      });
      this.$store.commit("setMessage", `что хотите вернуть билет на сумму ${this.refundAmount}`,);
      this.$store.commit("openDialog", true);
    },
    async GoToRefund() {
      this.loading = true;
      const groupedByLocator = this.selected.reduce((acc, {blankId, locator}) => {
        if (!acc[locator]) {
          acc[locator] = [];
        }
        acc[locator].push(blankId);
        return acc;
      }, {});
      const refundRequests = Object.entries(groupedByLocator).map(([locator, blanksIds]) => {
        const blanksId = blanksIds.join(' '); // Преобразование массива blankId в строку
        return this.$axios.get(`${this.$enums.Endpoints.RailwayOrder.Refund}?locator=${locator}&blanksid=${blanksId}`);
      });

      try {
        const responses = await Promise.all(refundRequests);
        responses.forEach((response, index) => {
          console.log("success", response.data.data);
          this.notify('Success', `Билеты с локатором ${Object.keys(groupedByLocator)[index]} успешно возвращены.`);
        });
      } catch (e) {
        this.notify('Error', `Error computing refund sum: ${e.message}`);
      } finally {
        this.loading = false;
        window.location.reload()
      }
    },

    updateSelected(ticket, segment) {
      // Create a new ticket object with only the necessary properties
      const newTicket = {
        blankId: ticket.blankId,
        locator: segment.locator
      };

      // Check if the new ticket is already in the selected array
      const existingIndex = this.selected.findIndex(
          selectedTicket => selectedTicket.blankId === newTicket.blankId && selectedTicket.locator === newTicket.locator
      );

      if (existingIndex === -1) {
        // If the checkbox is checked and the new ticket is not in the array, add it
        this.selected.push(newTicket);
      } else {
        // If the checkbox is unchecked and the new ticket is in the array, remove it
        this.selected.splice(existingIndex, 1);
      }
    },

    async onComputeRefundSum() {
      this.$store.dispatch({
        type: "setActions",
        accept: async () => {
          await this.computeRefundSum();
        },
      });
      this.$store.commit("setMessage", `что хотите рассчитать сумму возврата`,);
      this.$store.commit("openDialog", true);
    },

    async computeRefundSum() {
      // Check if there are any selected tickets
      if (this.selected.length === 0) {
        this.notify('Error', 'No tickets selected.');
        return;
      }

      this.loading = true;
      let refundTotal = 0;
      let maFees = 0;
      let serviceSum = 0;
      let refundComs = 0;
      let penaltySum = 0;

      try {
        // Process each selected ticket
        for (let {blankId, locator} of this.selected) {
          // Perform the API request for each ticket
          const body = {
            locator: locator,
            blanksId: blankId
          }
          const response = await this.$axios.get(this.$enums.Endpoints.RailwayOrder.GetRefundAmount, {params: body});

          // Handle specific business logic error
          if (response.data.data.message === "Заказ не может быть возвращен") {
            this.notify('Warning', `Ticket ${blankId} cannot be refunded.`);
            continue; // Skip this iteration, proceed with next ticket
          }

          // Assuming the response contains the total refund amount for each ticket
          refundTotal += response.data.data.amount
          maFees += response.data.data.maFee
          serviceSum += response.data.data.serviceSum
          response.data.data.ticketBlanks.forEach(ticketBlank => {
            refundComs += ticketBlank.refundCommission;
            penaltySum += ticketBlank.penalty;
          });
        }

        if (refundTotal > 0) {
          this.refundAmount = refundTotal.toFixed(2);
          this.maFee = maFees.toFixed(2);
          this.serviceSum = serviceSum.toFixed(2);
          this.refundComs = refundComs.toFixed(2)
          this.penalty = penaltySum.toFixed(2)
        }

      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false;
      }
    },


    closeModal() {
      this.$emit('update:isVisible', false);
    },
    refund() {

    }
  },
  mounted() {
  },
  watch: {
    selected: {
      handler() {
        this.refundAmount = 0;
        this.maFee = 0;
        this.serviceSum = 0;
      },
      deep: true
    }
  }
}
</script>

<style scoped>

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background: #1e1e1e;
  padding: 20px;
  border-radius: 5px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.refund-amount-display {

  background-color: #4CAF50;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  font-size: 0.9rem;
}

.modal-body {

  margin-top: 20px;
}

.tickets-container {
  max-height: 800px; /* Установите желаемую максимальную высоту контейнера */
  overflow: auto; /* Добавьте свойство overflow: auto для добавления полос прокрутки при необходимости */
}

.ticket-info + .ticket-info {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #3a3232;
}

.refund-section {
  /* Ensures that this section stays static */
  position: sticky;
  top: 0;
  background: rgba(255, 255, 255, 0);
  z-index: 100;
  overflow-x: hidden;
  overflow-y: hidden;
}

.table-container {
  /* Defines max height and enables vertical scrolling for the table */
  max-height: 600px;
  overflow-y: auto;
}

.tickets-table th {
  /* Styling adjustments for table header */
}

.tickets-table .ticket-info + .ticket-info {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #eee;
}
</style>